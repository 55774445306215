$blue: #20136a;
$light-blue: #0290dd;
$shadow: 0 4px 12px rgba(0, 0, 0, 0.075) !important;

.link-primary {
  color: $light-blue;
}

body.app-default {
  font-size: 16px!important;
  background: linear-gradient(to top, rgb(224, 248, 252), rgba(225, 238, 250, 0.69) 28.57%, rgb(255, 255, 255));
  .app-page {
    .app-header {
      background-color: transparent;
      box-shadow: none;
    }
    .app-wrapper {
      .app-sidebar {
        background: transparent;
        .app-sidebar-logo {
          border-bottom: 0;
          padding-left: 30px;
        }
        .app-sidebar-menu {
          .app-sidebar-wrapper {
            .menu {
              padding-left: 30px;
              .menu-link {
                padding: .75rem 1.25rem;
                &.active {
                  background-color: white;
                  box-shadow: $shadow;
                  .menu-title {
                    font-weight: bold;
                    color: $blue;
                  }
                  .menu-icon {
                    .svg-icon {
                      color: $blue;
                    }
                  }
                }
              }
            }
          }
        }
      }
      .app-main {
        .app-toolbar {
          display: none;
        }
        .app-content {
          .app-container {
            & > .card {
              height: calc(100vh - 100px);
              overflow: auto;
              box-shadow: 0 4px 12px rgba(0, 0, 0, 0.075) !important;
              .dashboard {
                .dashboard-item {
                  background-color: #f4f9fd;
                  border-radius: 1rem;
                  h3 {
                    color: $blue;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .uploadFile {
    color: grey;
    font-size: 16px;
    line-height: 23px;
    overflow: hidden;
    position: relative;
    resize: none;
    display: flex;
    align-items: center;

    .filename {
      border: 1px solid #bdbdbd;
      border-radius: 4px;
      display: block;
      height: 43px;
      width: 100%;
      line-height: 43px;
      padding: 0 10px;
    }

    [type="file"] {
      cursor: pointer !important;
      display: block;
      font-size: 999px;
      filter: alpha(opacity=0);
      opacity: 0;
      position: absolute;
      right: 0px;
      top: 0px;
      bottom: 0px;;
      text-align: right;
      z-index: 1;
    }
  }
}